import React, { useState, useRef } from 'react';
import '../styles/TrialClass.css';
import PTE from '../assets/Images/PTELOGO.png';
import emailjs from '@emailjs/browser';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Input from './InputContent';
import InputContent from './InputContent';

import 'font-awesome/css/font-awesome.min.css'; // Import the Font Awesome CSS
import countryData from 'country-data';
import { useNavigate } from 'react-router-dom';

const TrialClass = () => {
  // .............................coutry...........................

  const [selectedCountry, setSelectedCountry] = useState('India (+91)');
  const [showDropdown, setShowDropdown] = useState(false);

  const countryCodesArray = Object.values(countryData.countries.all).map((country) => ({
    name: country.name,
    dial_code: country.countryCallingCodes[0],
  }));

  const handleCountryClick = (country) => {
    setSelectedCountry(`${country.name} (${country.dial_code})`);
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  //..............................country............................
  // const [isFocused, setIsFocused] = useState(false);

  // const handleFocus = () => {
  //   setIsFocused(true);
  // };

  // const handleBlur = () => {
  //   setIsFocused(false);
  // };
  const navigate = useNavigate()

  const [flag,setFlag] = useState(0)

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState('');

  const [location, setLocation] = useState('')
  const [locationError, setLocationError] = useState('')
  const [placeError, setPlaceError] = useState('')

  const [selectedReason, setSelectedReason] = useState('');
  const [selectedReasonError, setSelectedReasonError] = useState('');


  // const form = useRef();

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   let isValid = true;
  //   // Validation for name and phone input fields (as before)




  //   if (isValid) {
  //     emailjs.sendForm(
  //       'service_q245vcd',
  //       'template_829d6ee',
  //       form.current,
  //       'GacdOWe7udB8UipqR'
  //     )
  //       .then((result) => {
  //         console.log(result.text);
  //         alert('message sent successfully');
  //         navigate('/')
  //       })
  //       .catch((error) => {
  //         console.log(error.text);
  //       });
  //   }
  // };

  const [showSecondSection, setShowSecondSection] = useState(false);
  const [showThirdSection, setShowThirdSection] = useState(false);
  const [showFourthSection, setShowFourthSection] = useState(false);








  const handleNextClick = (e) => {
    e.preventDefault();

    if (!e.target.value) { 
     console.log(e.target.name+'e.target.name testing');
      //validation of name
     if (!name.trim()) {
      setNameError('This field is required.');
     
    } else if (!/^[A-Za-z\s]+$/.test(name)) {
      setNameError('Invalid name');
    
    } else {
      setNameError('');
    }

    //drop down

    if (!selectedReason) {
      setSelectedReasonError('This field is required'); // Set an error message for the dropdown
      
     
    } else {
      setSelectedReasonError(''); // Clear the error message if a valid option is selected
    }
    
     }
     

    //this is for hiding
    if (name.trim() !== '' && !showSecondSection) {
      setShowSecondSection(true);
    } else if (selectedReason !== '' && !showThirdSection) {
      setShowThirdSection(true);
    } else if (phone !== '' && !showFourthSection) {
      setShowFourthSection(true); // Show the location input section
    }
    // You can keep the "else" block empty if you don't need any specific behavior in that case
  };




  const phoneValue = phone; // Store the phone value in a variable
  const nameValue = name


  //------------------- connection of backend -----------------------

 

  //------------------- connection of backend -----------------------

  return (
    <div className='trail-class'>
      <form>
        {showFourthSection ? (
          <div className='container'>
            <div className='row'>
              <div className='col-xl-6 col-lg-8 col-md-10 col-sm-12 mx-auto'>
                <div className="pte_loc text-center">
                  <div>
                    <img src={PTE} alt="" />
                    <h2>Your location *</h2>
                    <input type="hidden" name='number' value={phoneValue} />
                    <input type="hidden" name='name' value={nameValue} />
                    <input type="hidden" name='choice' value={selectedReason} />
                    <input type="hidden" name='country' value={selectedCountry} />
                    <input
                      type="text"
                      name="place"
                      className="form-control"
                      placeholder="Type your location here..."
                      value={location}
                      onChange={(e) => {
                        setLocation(e.target.value);
                        setLocationError('')
                      }}

                    />
                  </div>
                  {locationError && !location && <p id="error">{locationError}</p>}
                  <div id='set-error'><div id='adjust-message'>{placeError && <p id='plce-error'>{placeError}</p>}</div>  </div>
                  {placeError ? null : <button id='add-ptn' onClick={''}>Submit</button>}

                  {/* <button id='add-ptn'>Submit</button> */}
                </div>
              </div>
            </div>
          </div>
        ) : showThirdSection ? (
          <div className='container'>
            <div className='row'>
              <div className='col-xl-6 col-lg-8 col-md-10 col-sm-12 mx-auto'>
                <div className='contact-num text-center'>
                  <img src={PTE} alt='' />
                  <h2>And tell me your contact Number? *</h2>
                  <p>The trainer will reach you out on this number for the trial class. (WhatsApp number preferred)</p>
                  <div className='inputTag'>

                    <div id='tag'>
                      {/* ------------------------------------------------ */}
                      {/* <div id="custom-dropdown">
                        <div id='drop'>
                          <input
                            className="form-control"
                            name='country'
                            type="text"
                            value={selectedCountry}
                            onClick={toggleDropdown}
                            readOnly
                          />
                          <i className={`fa fa-caret-down ${showDropdown ? 'up' : 'down'}`} onClick={toggleDropdown}></i>
                        </div>
                        {showDropdown ? (
                          <div className="country-dropdown">
                            {countryCodesArray.map((country, index) => (
                              <div
                                key={index}
                                className="country-option"
                                onClick={() => handleCountryClick(country)}
                              >
                               
                                {country.name} ({country.dial_code})
                              </div>
                            ))}
                          </div>
                        ) : null}
                      </div> */}
                      <div id="custom-dropdown">
                        <select
                          id='countrySelect'
                          className="form-control"
                          name='country'
                          value={selectedCountry}
                          onChange={(e) => setSelectedCountry(e.target.value)}
                        >
                          {countryCodesArray.map((country, index) => (
                            <option key={index} value={`${country.name} (${country.dial_code})`}>
                              {country.name} ({country.dial_code})
                            </option>
                          ))}
                        </select>
                        <i className={`fa fa-caret-down ${showDropdown ? 'up' : 'down'}`} onClick={toggleDropdown}></i>
                      </div>
                      {/* ------------------------------------------------------ */}
                      <div id='personel-num'>
                        <input
                          type="text"
                          name='number'
                          className="form-control"
                          placeholder='Type your mobile number here'
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                            setPhoneError(''); // Clear the error when the user starts typing
                            setLocationError('')
                            setPlaceError('')
                          }}
                        />
                        {/* <input type="text" name='number' className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} /> */}
                      </div>
                    </div>
                    {/* {phoneError && <p id='error-messageges'>{phoneError}</p>} */}
                    {phoneError && !phone && <p id='error-messageges'>{phoneError}</p>}
                  </div>

                  <button id='contact-button' onClick={handleNextClick}>Next</button>
                </div>
              </div>
            </div>
          </div>
        ) : showSecondSection ? (
          <div className='container'>
            <div className='row'>
              <div className='col-xl-6 col-lg-8 col-md-10 col-sm-12 mx-auto'>
                <div className='pte_department'>
                  <img src={PTE} alt='' />
                  <h2></h2>
                  <h2>{`Okay, ${name}, please tell me why you want to take PTE? *`}</h2> {/* Display name here */}
                  {/* <select name='choice' id='choose' onChange={(e) => setSelectedReason(e.target.value)}>
                    <option value=''>--select--</option>
                    <option value='Study Abroad'>Study Abroad</option>
                    <option value='Migration'>Migration</option>
                    <option value='Job'>Job</option>
                    <option value='Other'>Other</option>
                  </select>
                  {selectedReasonError && <p id="check">{selectedReasonError}</p>} */}
                  <select name='choice' value={selectedReason} id='choose' onChange={(e) => {
                    setSelectedReason(e.target.value);
                    setSelectedReasonError(''); // Clear the error when the user selects an option
                    setPhoneError('')
                    setLocationError('')
                    setPlaceError('')
                  }}>
                    <option value='' selected disabled>--select--</option>
                    <option value='Study Abroad'>Study Abroad</option>
                    <option value='Migration'>Migration</option>
                    <option value='Job'>Job</option>
                    <option value='Other'>Other</option>
                  </select>
                  {selectedReasonError && !selectedReason && <p id="check">{selectedReasonError}</p>}


                  <button id='select_button' onClick={handleNextClick}>
                    NEXT
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='container-xl'>
            <div className='row'>
              <div className='col-xl-6 col-lg-8 col-md-10 col-sm-12 mx-auto'>
                <div className='top-contents text-center'>
                  <img src={PTE} alt='' />
                  <h2 id='score'>Achieve your target score in the first attempt itself</h2>
                  <h4 className=''>No.1 PTE Training Platform</h4>
                  <p className=''>
                    Get a trial class to see how we can help you get a 79+ score on your next attempt
                  </p>
                  <h1>Hey! What's your name? *</h1>
                  {/* <input
                    type='text'
                    name='name'
                    className='form-control'
                    placeholder='Type your name here...'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {nameError && <p id="error-messages">{nameError}</p>} */}
                  <input
                    type='text'
                    name='name'
                    id='name'
                    className='form-control'
                    placeholder='Type your name here...'
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameError(''); // Clear the name error when the user starts typing
                      setSelectedReasonError('')
                      setPhoneError('')
                      setLocationError('')
                      setPlaceError('')
                    }}
                  />
                  {nameError && !name && <p id="error-messages">{nameError}</p>}
                  <button className='btn mt-3' onClick={handleNextClick}>
                    NEXT
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}


      </form>
    </div>
  );
};

export default TrialClass;
