import React from 'react'
import '../styles/PayNow.css'
import scanner from '../assets/Images/scanner.jpg'
import Pte_Footer from '../component/Pte_Footer'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'
import Footer from './Footer'


const PayNow = () => {
    return (
        <div className='pay-money'>

            <Navbar/>
            <div className='payment container'>
                <h1 id='payment-title'>CHOOSE FROM THE FOLLOWING PAYMENT OPTIONS</h1>
                <div className="options row">
                    {/* UPI */}
                    <div className="qr-scan col-xl-4 col-lg-4 col-sm-12">
                        <button>1</button>
                        <h3>UPI</h3>
                        <img src={scanner} alt="" />
                    </div>

                    {/* Cash Deposit or Money Transfer */}
                    <div className="transfer col-xl-4 col-lg-4 col-sm-12">
                        <button>2</button>
                        <h3>Cash Deposit or Money Transfer</h3>
                        <p>Account Name: ASCEDU SMARTCLASSON LLP</p>
                        <p>Account Number: 923020014345748</p>
                        <p>IFSC Code: UTIB0003739</p>
                        <p>Bank Name: Axis Bank Limited</p>
                    </div>

                    {/* Cards or Internet Banking */}
                    <div className="banking col-xl-4 col-lg-4 col-sm-12">
                        <button>3</button>
                        <h3>Cards or Internet Banking</h3>

                        <Link id='click-here' to="https://pages.razorpay.com/IELTSMaster">CLICK HERE</Link>
                        <Link id='razorpay' to="https://rzp.io/l/hpqM9DDyK"><p>Razorpay</p></Link>

                    </div>
                </div>
            </div>


            <Footer/>
        </div>
    )
}

export default PayNow
