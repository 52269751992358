import React, { useEffect, useState } from 'react'
import logo from '../assets/Images/PTELOGO.png'
// import { BiSearch } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiSolidDownArrow } from "react-icons/bi";
import { FiChevronDown } from "react-icons/fi";
// import nixvpn2 from '../assets/Images/nixvpnLogo.png'
import { GiHamburgerMenu } from "react-icons/gi";

import '../styles/Navbar.css'
import { Link } from 'react-router-dom';

// import 'typeface-roboto';

const Navbar = () => {

    const [showSidebar, setShowSidebar] = useState(false);
    const [showHowItWorksOptions, setShowHowItWorksOptions] = useState(false);

    const [navbarBackground, setNavbarBackground] = useState('rgba(0, 0, 0, 0)');
    // const [textColor, setTextColor] = useState('white');
    const [imageSrc, setImageSrc] = useState(logo);
    const [hamburgerBtnColor, setHamburgerBtnColor] = useState('white');
    const [navbarBoxShadow, setNavbarBoxShadow] = useState('none');
    const [isSearchVisible, setIsSearchVisible] = useState(false);

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const closeSidebar = () => {
        setShowSidebar(false);
    };

    const toggleHowItWorksOptions = () => {
        setShowHowItWorksOptions(!showHowItWorksOptions);
    };

    const toggleSearch = () => {
        setIsSearchVisible(!isSearchVisible);
    };

    const closeSearch = () => {
        setIsSearchVisible(false);
    };

    const changeBackgroundAndTextOnScroll = () => {
        if (window.scrollY > 0) {
            setNavbarBackground('white');
            // setTextColor('black');
            setImageSrc(logo);
            setHamburgerBtnColor('white');

            setNavbarBoxShadow('0 3px 16px rgba(0, 0, 0, 0.1)');
        } else {
            setNavbarBackground('white');
            // setTextColor('white');
            setImageSrc(logo);
            setHamburgerBtnColor('white');

            setNavbarBoxShadow('none')
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeBackgroundAndTextOnScroll);

        // Initial call to set the text color based on the initial window size
        changeBackgroundAndTextOnScroll();
        return () => {
            window.removeEventListener('scroll', changeBackgroundAndTextOnScroll);
        };
    }, []);

    return (
        <div className="new-navbar" style={{ backgroundColor: navbarBackground, boxShadow: navbarBoxShadow }}>
            <Link to={'/'}>
                <div className="navbar-logo">
                    <img src={logo} alt="Logo" />
                </div>
            </Link>

            <div className={`menu-icon ${showSidebar ? 'activeNav' : ''}`} onClick={toggleSidebar}>
                <GiHamburgerMenu id='hamBurger'/>

                {/* <div className="bar" style={{ backgroundColor: hamburgerBtnColor }}></div>
                <div className="bar" style={{ backgroundColor: hamburgerBtnColor }}></div>
                <div className="bar" style={{ backgroundColor: hamburgerBtnColor }}></div> */}
            </div>

            <ul className='navbar-options'>
                <Link to={'/'} onClick={() => window.location.href('/')}>
                    <div>Home</div>
                </Link>

                {/* <Link to={''} onClick={() => window.location.href('')}>
                <div >Check Your Level</div>
            </Link> */}

                <Link to={'https://courses.ieltsmaster.com/PTESKILLS'}>
                    <div >Courses</div>
                </Link>

                <Link to={''}>
                    <div onClick={() => {
                        const features = document.getElementById("features");
                        if (features) {
                            features.scrollIntoView({ behavior: "smooth" });
                        }
                    }}>Features</div>
                </Link>
                <Link to={''}>
                    <div onClick={() => {
                        const pteReview = document.getElementById("pte-review");
                        if (pteReview) {
                            pteReview.scrollIntoView({ behavior: "smooth" });
                        }
                    }}>Testimonials</div>
                </Link>

                {/* <Link to={''}>
                    <div onClick={() => {
                        const setup = document.getElementById("setup");
                        const subSlides = document.getElementById("sub-slides");

                        if (setup) {
                            setup.scrollIntoView({ behavior: "smooth" });
                        }

                        if (subSlides) {
                            subSlides.scrollIntoView({ behavior: "smooth" });
                        }
                    }}>Testimonials</div>
                </Link> */}

                <Link to={''}>
                    <div onClick={() => {
                        const questions = document.getElementById("questions");
                        if (questions) {
                            questions.scrollIntoView({ behavior: "smooth" });
                        }
                    }}>FAQs</div>
                </Link>

                {/* <Link to={'https://reports2.ieltsmaster.com/index.php/Student'}>
                    <div>Login</div>
                </Link> */}
            </ul>

            <div className={`sidebar ${showSidebar ? 'activeNav' : ''}`}>
                <div className="close-section">
                    <div className='close-btn'>
                        <button onClick={closeSidebar}><AiOutlineCloseCircle /></button>
                    </div>
                </div>

                <div className="options-section">
                    <Link to={'/'} onClick={(e) => { closeSidebar(); e.preventDefault(); window.location.assign('/'); }}>
                        <div className='sidebar-option-home'>
                            <div>Home</div>
                            <span><BiSolidDownArrow /></span>
                        </div>
                    </Link>
                    {/* <Link to={''} onClick={(e) => { closeSidebar(); e.preventDefault(); window.location.assign(''); }}>
                    <div className='sidebar-option-level'>
                        <div>Check Your Level</div>
                        <span><BiSolidDownArrow /></span>
                    </div>
                </Link> */}
                    <Link to={'https://courses.ieltsmaster.com/PTESKILLS'}>
                        <div className='sidebar-option-about'>
                            <div>Courses</div>
                            <span><BiSolidDownArrow /></span>
                        </div>
                    </Link>
                    <Link to={''}>
                        <div className='sidebar-option-features'>
                            <div onClick={() => {
                                const features = document.getElementById("features");
                                if (features) {
                                    features.scrollIntoView({ behavior: "smooth" });
                                }
                            }}>Features</div>
                            <span><BiSolidDownArrow /></span>
                        </div>
                    </Link>
                    <Link to={''}>
                        <div className='sidebar-option-features'>
                            <div id='testimonial-sec' onClick={() => {
                                const pteReview = document.getElementById("pte-review");
                                if (pteReview ) {
                                    pteReview .scrollIntoView({ behavior: "smooth" });
                                }
                            }}>Testimonials</div>
                            <span><BiSolidDownArrow /></span>
                        </div>
                    </Link>
                    {/* <Link to={''}>
                        <div className='sidebar-option-testimonial'>
                            <div onClick={() => {
                                const setup = document.getElementById("setup")
                                if (setup) {
                                    setup.scrollIntoView({ behavior: "smooth" })
                                }
                            }}>Testimonials</div>
                            <span><BiSolidDownArrow /></span>
                        </div>
                    </Link> */}
                    <Link to={''}>
                        <div className='sidebar-option-contact'>
                            <div onClick={() => {
                                const questions = document.getElementById("questions");
                                const subSlides = document.getElementById("sub-slides");
                                if (questions) {
                                    questions.scrollIntoView({ behavior: "smooth" });
                                }
                                if (subSlides) {
                                    subSlides.scrollIntoView({ behavior: "smooth" });
                                }
                            }}>FAQs</div>
                            <span><BiSolidDownArrow /></span>
                        </div>
                    </Link>
                    {/* <Link to={'https://reports2.ieltsmaster.com/index.php/Student'}>
                        <div className='sidebar-option-contact'>
                            <div>Login</div>
                            <span><BiSolidDownArrow /></span>
                        </div>
                    </Link> */}

                </div>
            </div>
        </div>
    )
}

export default Navbar