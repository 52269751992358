
import React from 'react';
import { FaWhatsapp } from "react-icons/fa";
import { Link } from 'react-router-dom';
import '../styles/WhatsAppButton.css';

function WhatsAppButton() {
  // Encode the message text to be included in the WhatsApp URL
  const message = encodeURIComponent("Hi IELTS Master, I need your assistance for my IELTS preparation!");

  // Create the WhatsApp URL with the prepopulated message
  const whatsappURL = `https://wa.me/+918593000876?text=${message}`;

  return (
    <div className='adding'>
      <button id='addButton'>
        {/* Use the whatsappURL as the href for the link */}
        <Link to={whatsappURL} target='_blank' rel='noopener noreferrer'>
          <FaWhatsapp />
        </Link>
      </button>
    </div>
  );
}

export default WhatsAppButton;


