import React, { useState, useEffect, useRef } from 'react';
import Navbar from './Navbar'
// import ReCAPTCHA from "react-google-recaptcha";


import emailjs from '@emailjs/browser';
// import styled from "styled-components";

import '../styles/HomePage.css'
import { BsDisplay, BsGraphUpArrow, BsPlayCircle, BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FaVideo, } from "react-icons/fa";
import { FaChalkboardUser } from "react-icons/fa6";
import { LuClipboardSignature } from "react-icons/lu";
import { TfiWrite } from "react-icons/tfi";
import { AiFillStar } from "react-icons/ai";
import { IoClose } from "react-icons/io5";



// import ReCAPTCHA from "react-google-recaptcha"; // google recaptcha import

import video_bg from '../assets/Images/study.png';
import men1 from '../assets/Images/men1.jpg';
import men2 from '../assets/Images/men2.jpg';
import men3 from '../assets/Images/men3.jpg';
import men4 from '../assets/Images/men4.jpg';
import men5 from '../assets/Images/men5.jpg';
import men6 from '../assets/Images/men6.jpg';
import men7 from '../assets/Images/men7.jpg';
import person3 from '../assets/Images/person3.jpg';
import { Link, useNavigate } from 'react-router-dom';
import 'fontsource-poppins'; // Import Poppins font CSS
import Footer from './Footer';
// import GoogleRecaptcha from './GoogleRecaptcha';

import WhatsAppButton from './WhatsAppButton';
import GoogleRecaptcha from './GoogleRecaptcha';


const HomePage = () => {
  const navigate = useNavigate()
  // ----------------------email---------------------

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    location: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    location: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform validation
    let isValid = true;
    const newErrors = { ...errors };

    if (!formData.name) {
      isValid = false;
      newErrors.name = 'Name is required';
    } else {
      newErrors.name = '';
    }

    if (!formData.phone) {
      isValid = false;
      newErrors.phone = 'Mobile number is required';
    } else {
      newErrors.phone = '';
    }

    if (!formData.location) {
      isValid = false;
      newErrors.location = 'Location name is required';
    } else {
      newErrors.location = '';
    }

    setErrors(newErrors);

    if (isValid) {
      // Perform submit logic
      console.log('Form submitted:', formData);
      // You can also make an API call or perform other actions here
    }
  };

  // ----------------------email---------------------

const handleRefresh = () =>{
  window.location.href = "/pay-now"
}



  const sliderRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(null);
  // const [isModalOpen, setModalOpen] = useState(false);

  // Function to open the modal
  // const openModal = () => {
  //   setModalOpen(true);
  // };

  // Function to close the modal
  // const closeModal = () => {
  //   setModalOpen(false);
  // };


  const handleAccordionClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));

  };

  const next = () => {
    const slider = sliderRef.current;
    const testimonials = slider.querySelectorAll('.testimonial-container');
    const firstTestimonial = testimonials[0];
    const nextTestimonial = testimonials[1];

    if (nextTestimonial) {
      slider.style.transition = 'transform 0.5s ease-in-out';
      slider.style.transform = `translateX(-${firstTestimonial.offsetWidth}px)`;

      setTimeout(() => {
        slider.appendChild(firstTestimonial);
        slider.style.transition = 'none';
        slider.style.transform = 'translateX(0)';
      }, 500);
    }
  };

  const back = () => {
    const slider = sliderRef.current;
    const testimonials = slider.querySelectorAll('.testimonial-container');
    const lastTestimonial = testimonials[testimonials.length - 1];
    const firstTestimonial = testimonials[0];

    slider.insertBefore(lastTestimonial, firstTestimonial);
    slider.style.transition = 'none';
    slider.style.transform = `translateX(-${firstTestimonial.offsetWidth}px)`;

    setTimeout(() => {
      slider.style.transition = 'transform 0.5s ease-in-out';
      slider.style.transform = 'translateX(0)';
    }, 0);
  };


  const clicks = (event) => {
    event.preventDefault();
    const siteFooter = document.getElementById("site-footer");
    if (siteFooter) {
      siteFooter.scrollIntoView({ behavior: "smooth" });
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      next();
    }, 80009999);

    return () => clearInterval(interval);
  }, []);

  const onChange = () => {

  }

  return (
    <div>
      <div id='pte-nav'><Navbar /></div>

      <div className="main-div">
        <div className="banner">
          <div className="banner-header">
            <div className='banner-header-wrapper'>
              <span className='bh-subtitle'>GET YOUR TARGET SCORE</span>
              <h2 className='bh-title'>IN JUST ONE ATTEMPT</h2>
              <h4 className='bh-text'>The Best PTE Training Platform in the World!</h4>
            </div>

            <div className="banner-btn">
              <div className='banner-btn-wrapper'>
                <Link to={'https://courses.ieltsmaster.com/PTESKILLS'}><button type="submit" className="course-btn">COURSES</button></Link>
                <button onClick={() => {
                  window.location.href = '/trial-class/';
                }} type='submit' className='trial-btn'>TRIAL CLASSES</button>
              </div>
            </div>
          </div>
          {/* <Link to='https://web.whatsapp.com/'><button className='whatsapp'><FaWhatsappSquare/></button></Link> */}
          <div className='whatsapp'>
            <WhatsAppButton />
          </div>
        </div>

        <div className="about-section">
          <div className="about-text">
            <div className="about-header">
              <h5>Why PTE Skills?</h5>
              <h1>About PTE Skills - PTE Coaching</h1>
            </div>
            <div className="about-para">
              <p>PTE Skills stands out as an eminent PTE coaching platform on a global scale. Our commitment to empowering students includes providing an array of invaluable resources: complimentary study materials, online video lectures, interactive live sessions, comprehensive online mock tests, and a live reporting system offering in-depth feedback. Our esteemed team comprises highly qualified instructors. With a remarkable success rate, exceeding 90%, our students consistently reach their target scores on their very first attempt. Now, it's your opportunity to shine!</p>
              {/* <button className='cf-submit-btn' onClick={clicks}>REQUEST A Quick Callback</button> */}
              <div className='course-feature-video'>
                <div className="course-features-right">
                  <div className="video-container">
                    <img src={video_bg} alt="" />
                    {/* <button onClick={openModal} className='play-btn'><BsPlayCircle /></button> */}

                    {/* {isModalOpen && (
                      <div className="modal">
                        <button className='close-button' onClick={closeModal}><IoClose /></button>
                        <div onClick={closeModal} className="overlay"></div>
                        <div className="modal-content">
                          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/01y-SPjNOH8?si=wtxiNKqAuW3HplQ5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                        </div>
                      </div>
                    )} */}

                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="callback-form-section">
            <div className='callback-form-container'>
              <div className='cf-header'>
                <span className='bh-subtitlez'>REQUEST A</span>
                <h2 className='bh-titlez'>Quick Callback</h2>
                <h5 className='cf-textz'>Our team will call you soon!</h5>
              </div>


              <div className='callback-form-wrapper'>
              {/* <form method="POST" action="https://backend.ieltsmaster.com/IELTS-Trialclass-backend/" onSubmit={handleSubmit}> */}
                <form method="POST" action="https://backend.ieltsmaster.com/PTE-Skills-backend/">
                  <label className='cf-label'>
                    Your Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    name='name'
                    value={formData.name}
                    onChange={handleChange}
                    className='form-control border border-secondary'
                  />
                  {errors.name && <p className="error-message">{errors.name}</p>}

                  <label className='cf-label'>
                    Phone (With Country Code) <span>*</span>
                  </label>
                  <input
                    type="text"
                    name='phone'
                    value={formData.phone}
                    onChange={handleChange}
                    className='form-control border border-secondary'
                  />
                  {errors.phone && <p className="error-message">{errors.phone}</p>}

                  <label className='cf-label'>
                    Location <span>*</span>
                  </label>
                  <input
                    type="text"
                    name='location'
                    value={formData.location}
                    onChange={handleChange}
                    className='form-control border border-secondary'
                  />
                  {errors.location && <p className="error-message">{errors.location}</p>}

                  <button className='cf-submit-btn' name="submit" type='submit'>
                    SUBMIT
                  </button>
                </form>
              </div>            </div>
          </div>


          {/* <div className='course-feature-video'>
                  <div className="course-features-right">
                    <div className="video-container">
                      <img src={video_bg} alt="" />
                      <button onClick={openModal} className='play-btn'><BsPlayCircle /></button>

                      {isModalOpen && (
                        <div className="modal">
                          <button className='close-button' onClick={closeModal}><IoClose /></button>
                          <div onClick={closeModal} className="overlay"></div>
                          <div className="modal-content">
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/01y-SPjNOH8?si=wtxiNKqAuW3HplQ5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                          </div>
                        </div>
                      )}

                    </div>
                  </div>
                </div> */}
          {/* <div className="callback-form-section">
            <div className='callback-form-container'>
              <div className='cf-header'>
                <span className='bh-subtitle'>REQUEST A</span>
                <h2 className='bh-title'>Quick Callback</h2>
                <h5 className='cf-text'>Our team will call you soon!</h5>
              </div>

              <div className='callback-form-wrapper'>
                <form action=""> */}
          {/* <label className='cf-label'>Your Name <span>*</span></label>
                  <input type="text" className='form-control border border-secondary' />

                  <label className='cf-label'>Phone (With Country Code) <span>*</span></label>
                  <input type="text" className='form-control border border-secondary' />

                  <label className='cf-label'>Location <span>*</span></label>
                  <input type="text" className='form-control border border-secondary' /> */}

          {/* <div className='captcha'>
                    <ReCAPTCHA sitekey="6LctwvInAAAAAHVhfcaM4E4imJijbMgBlm9F07lS" onChange={onChange} style={{ paddingTop: '1.5rem', width: '100%' }} />
                  </div> */}
          {/* 
                  <button className='cf-submit-btn' onClick={clicks}>submit</button>

                </form>
              </div>
            </div>
          </div> */}
        </div>

        <div className="course-section" id='features'>
          <div className="course-container">
            <div className='course-wrapper'>
              <div className="course-header">
                <span className='bh-subtitle2'>KNOW MORE...</span>
                <h2 className='bh-title2'>Our Course Attractions</h2>
              </div>
              <div className="course-features">
                <div className='course-feature-text'>
                  <div className="course-features-left">
                    <div className="features">
                      <div className="feature-icon">
                        <BsDisplay />
                      </div>
                      <div className="feature-text">
                        <div className="feature-text-header">
                          <h3 className='feature-icon-title'>PTE Master Student Portal</h3>
                        </div>
                        <div className="feature-text-para">
                          <p className='feature-icon-text'>Our portak is a repository of , practice questions, sample essays, vocabulary and collocations.</p>
                        </div>
                      </div>
                    </div>

                    <div className="features">
                      <div className="feature-icon">
                        <FaVideo />
                      </div>
                      <div className="feature-text">
                        <div className="feature-text-header">
                          <h3 className='feature-icon-title'>Live Interactive Sessions</h3>
                        </div>
                        <div className="feature-text-para">
                          <p className='feature-icon-text'>This session provides a comprehensive view of the patterns,strategies, tips and viable templates to tackle all questions.</p>
                        </div>
                      </div>
                    </div>

                    <div className="features">
                      <div className="feature-icon">
                        <LuClipboardSignature />
                      </div>
                      <div className="feature-text">
                        <div className="feature-text-header">
                          <h3 className='feature-icon-title'>Practices and Detailed feedback</h3>
                        </div>
                        <div className="feature-text-para">
                          <p className='feature-icon-text'>You can get access to the comprehensive study materials, and practice exercises with feedback.</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="course-features-middle">
                    <div className="features">
                      <div className="feature-icon">
                        <FaChalkboardUser />
                      </div>
                      <div className="feature-text">
                        <div className="feature-text-header">
                          <h3 className='feature-icon-title'>Personal Mentor Support</h3>
                        </div>
                        <div className="feature-text-para">
                          <p className='feature-icon-text'>The mentor will give guidelines about the different tasks, practice lessons, detailed evaluation and personalized feedback.</p>
                        </div>
                      </div>
                    </div>

                    <div className="features">
                      <div className="feature-icon">
                        <BsGraphUpArrow />
                      </div>
                      <div className="feature-text">
                        <div className="feature-text-header">
                          <h3 className='feature-icon-title'>PTE Skills Master Student Report Card</h3>
                        </div>
                        <div className="feature-text-para">
                          <p className='feature-icon-text'>Students can access the report card to know their target score, the current score and the feedback.</p>
                        </div>
                      </div>
                    </div>

                    <div className="features">
                      <div className="feature-icon">
                        <TfiWrite />
                      </div>
                      <div className="feature-text">
                        <div className="feature-text-header">
                          <h3 className='feature-icon-title'>MOCK TEST</h3>
                        </div>
                        <div className="feature-text-para">
                          <p className='feature-icon-text'>The simulated PTE practice tests will enable students to get acquained with the real examination conditions.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='course-feature-video'>
                  <div className="course-features-right">
                    <div className="video-container">
                      <img src={video_bg} alt="" />
                      <button onClick={openModal} className='play-btn'><BsPlayCircle /></button>

                      {isModalOpen && (
                        <div className="modal">
                          <button className='close-button' onClick={closeModal}><IoClose /></button>
                          <div onClick={closeModal} className="overlay"></div>
                          <div className="modal-content">
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/01y-SPjNOH8?si=wtxiNKqAuW3HplQ5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                          </div>
                        </div>
                      )}

                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="review-section" id='pte-review'>
          <div className="review-header">
            <h6>Success Stories...</h6>
          </div>

          <div className="review-testimonial-slider">
            <div className="slider-container">
              <div className='slider-control'>
                <button onClick={back}><BsChevronLeft /></button>
              </div>

              <div className="testimonial-slider-wrapper">
                <div className="testimonial-slider" ref={sliderRef}>
                  <div className="testimonial-container">
                    <div className="testimonial">
                      <div className='testimonial-header'>
                        <img src={men1} />
                        <div className="testimonial-info">
                          <h3>Sinsha</h3>
                          <div className="rating">
                            <span className="star">
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                            </span>
                          </div>
                          <h6>Trivandrum</h6>
                        </div>
                      </div>
                      <div id='ports1'><p className="review">I can't thank PTE Skills enough for helping me achieve my desired score. The experienced instructors, comprehensive study materials, and simulated practice tests were instrumental in boosting my confidence. Highly recommended!</p></div>
                    </div>
                  </div>
                  <div className="testimonial-container">
                    <div className="testimonial">
                      <div className='testimonial-header'>
                        <img src={men2} />
                        <div className="testimonial-info">
                          <h3>Abhishek Mishra</h3>
                          <div className="rating">
                            <span className="star">
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                            </span>
                          </div>
                          <h6>Hyderabad</h6>
                        </div>
                      </div>
                      <div id='ports2'><p className="review">I was struggling with the PTE exam, but after enrolling in this training program, I saw a remarkable improvement in my skills. The trainers are knowledgeable, patient, and offer personalized guidance. I'm thrilled with my results! I am flying to Australia next week.</p></div>
                    </div>
                  </div>


                  <div className="testimonial-container">
                    <div className="testimonial">
                      <div className='testimonial-header'>
                        <img src={men3} />
                        <div className="testimonial-info">
                          <h3>Venkatesh Raparthi</h3>
                          <div className="rating">
                            <span className="star">
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                            </span>
                          </div>
                          <h6>Goa</h6>
                        </div>
                      </div>
                      <div id='ports3'><p className="review"> PTE Skills provided me with a structured approach to tackle all sections of the test. Thanks to their guidance and constant feedback, I was able to achieve my target score. It was worth every penny!</p></div>
                    </div>
                  </div>

                  <div className="testimonial-container">
                    <div className="testimonial">
                      <div className='testimonial-header'>
                        <img src={men4} />
                        <div className="testimonial-info">
                          <h3>Kartik Sharma</h3>
                          <div className="rating">
                            <span className="star">
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                            </span>
                          </div>
                          <h6>Gurgaon</h6>
                        </div>
                      </div>
                      <div id='ports4'><p className="review"> PTE Skills team are not just teachers; they are mentors who genuinely care about your success. The tips and strategies they shared for the PTE exam were invaluable. I couldn't have achieved my goal of 79 in all skills without their support.</p></div>
                    </div>
                  </div>

                  {/* <div className="testimonial-container">
                    <div className="testimonial">
                      <div className='testimonial-header'>
                        <img src={men5} />
                        <div className="testimonial-info">
                          <h3>Aaska patel</h3>
                          <div className="rating">
                            <span className="star">
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                            </span>
                          </div>
                          <h6>Noida</h6>
                        </div>
                      </div>
                      <div id='ports5'><p className="review"> I had a limited timeframe to prepare for the PTE exam, but this institute's PTE course proved to be a game-changer. The intense practice sessions and expert guidance helped me ace the test. I highly recommend it to anyone looking for a quick but effective PTE preparation.<strong>Overall Band 8</strong>(L:8 R:8 W:7 S:7)</p></div>
                    </div>
                  </div> */}

                  {/* <div className="testimonial-container">
                    <div className="testimonial">
                      <div className='testimonial-header'>
                        <img src={men6} />
                        <div className="testimonial-info">
                          <h3>Dr Sajna</h3>
                          <div className="rating">
                            <span className="star">
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                            </span>
                          </div>
                          <h6>Manjeri</h6>
                        </div>
                      </div>
                      <div id='ports6'><p className="review">Enrolling in PTE Skills was the best decision I made for my academic and career goals. The top-notch guidance and support I received led to an outstanding PTE score of overall 85, that opened up new opportunities for me.</p></div>
                    </div>
                  </div> */}
                  {/* <div className="testimonial-container">
                    <div className="testimonial">
                      <div className='testimonial-header'>
                        <img src={men7} />
                        <div className="testimonial-info">
                          <h3>Vidya Hegde</h3>
                          <div className="rating">
                            <span className="star">
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                              <AiFillStar />
                            </span>
                          </div>
                          <h6>Bangalore</h6>
                        </div>
                      </div>
                      <div id='ports7'><p className="review"> I shall recommend IELTS Master to anyone appearing for IELTS. Thank you <strong>Overall Band 8</strong>(L:8.5 R:8 W:7 S:7.5)</p></div>
                    </div>
                  </div> */}
                  {/* Add more testimonials here */}
                </div>
              </div>

              <div className='slider-control'>
                <button onClick={next}><BsChevronRight /></button>
              </div>
            </div>

          </div>
        </div>


        <div className="faq-section" id='questions'>
          <div className="faq-container">

            <div className="faq-header">
              <span className='bh-subtitle1'>ALL YOU NEED TO KNOW</span>
              <h2 className='bh-title1'>Frequently Asked Questions</h2>
            </div>

            <button className={`accordion ${activeIndex === 0 ? "active" : ""}`} onClick={() => handleAccordionClick(0)}>
              How can I get a 79+ score in PTE.
            </button>
            <div className={`panel ${activeIndex === 0 ? "show" : ""}`}>
              <p className='panel-text'>You can get a score of your need by attending the PTE Skills classes, practice tests and getting regular feedback from our expert tutors.</p>
            </div>

            <button className={`accordion ${activeIndex === 1 ? "active" : ""}`} onClick={() => handleAccordionClick(1)}>
              Am I eligible to join the PTE Online Course if I don’t have good fluency in English?
            </button>
            <div className={`panel ${activeIndex === 1 ? "show" : ""}`}>
              <p className='panel-text'>Yes, you are. First, we will teach you the basic language skills in the Pre PTE Course, and then you will be sent to the PTE Online classes.</p>
            </div>

            <button className={`accordion ${activeIndex === 2 ? "active" : ""}`} onClick={() => handleAccordionClick(2)}>
              Do you provide Listening and Reading practice tests?
            </button>
            <div className={`panel ${activeIndex === 2 ? "show" : ""}`}>
              <p className='panel-text'>Yes, we do. We have a rich collection of exercises for individual task types in listening and reading, as well as, sectional mocks and full-length mocks with reviews in the portal.</p>
            </div>

            <button className={`accordion ${activeIndex === 3 ? "active" : ""}`} onClick={() => handleAccordionClick(3)}>
              Can I get Writing corrections and speaking tests with this course?
            </button>
            <div className={`panel ${activeIndex === 3 ? "show" : ""}`}>
              <p className='panel-text'>Yes, you can. We understand that the writing and speaking sections are crucial components of the PTE exam, and we are committed to helping you excel in these areas. You can submit your written responses, and our experienced instructors will evaluate them, providing detailed feedback on areas for improvement. We offer simulated speaking tests that closely resemble the actual PTE speaking section. You can practice your speaking skills, and we provide feedback and scores based on the PTE scoring criteria.</p>
            </div>

            <button className={`accordion ${activeIndex === 4 ? "active" : ""}`} onClick={() => handleAccordionClick(4)}>
              Can I join the course from abroad?
            </button>
            <div className={`panel ${activeIndex === 4 ? "show" : ""}`}>
              <p className='panel-text'>Yes, of course. You can do this course from any part of the world as we have flexible batches that suit your timing.</p>
            </div>

            <button className={`accordion ${activeIndex === 5 ? "active" : ""}`} onClick={() => handleAccordionClick(5)}>
              How can I join this course?
            </button>
            <div className={`panel ${activeIndex === 5 ? "show" : ""}`}>
              <p className='panel-text'> You can join the course in three steps.
                <li className='join-steps'>&nbsp; Find your suitable course from this link; <Link to='https://courses.ieltsmaster.com/fees-guide' className='links'>Courses</Link></li>
                <li className='join-steps'>&nbsp; Make the payment with this link. <Link onClick={handleRefresh} className='links'>Pay Now</Link></li>
                <li className='join-steps'>&nbsp; Fill this form; <Link to='https://reports2.ieltsmaster.com/index.php/Student' className='links'>Student Application Form</Link> and then  you will get quick response from our end</li>
              </p>
            </div>

            <button className={`accordion ${activeIndex === 6 ? "active" : ""}`} onClick={() => handleAccordionClick(6)}>
              Do you accept Credit card to make the payment?
            </button>
            <div className={`panel ${activeIndex === 6 ? "show" : ""}`}>
              <p className='panel-text'>Yes, we do.  We accept all cards including debit cards , credit cards, internet banking, and UPI. Please go to this link: <Link onClick={handleRefresh} className='links'>Pay Now</Link></p>
            </div>

          </div>
        </div>

        {/* ...............................movivg........................................ */}

        {/* <div class="slider">
        <div className='testimonial-headers'><h1>Testimonials</h1></div>
	<div class="slide-track">
    
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/01y-SPjNOH8?si=uTxwH7n-R5bMMZ6h" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/dU-fboZrh1g?si=aBBmIrYR8wUMZK8n" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/PfU4tvDn7gY?si=zaKgNQ55ah97vRlE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/hrt0DST6F-c?si=odXoGkIURSCkz0RP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/li-M-QQOz4s?si=bVeTgDcwE2fdKREW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/8c7mcU_JUp8?si=A6vYOnqqIrzqJUFV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/g2JbtqlTK-g?si=Y44RL_0jgv02SPte" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/jMjCE7ZiScY?si=wfQXNJ-PKtG597t-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/mne8boakoc4?si=NHQe9DXYtSZ2wfDH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/77X5WOHwQ5c?si=lMxBclF8F9Acg5dS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' width='560' src="https://www.youtube.com/embed/e0XVOQ5mkDw?si=8caC543825OIDyGm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe src="https://www.youtube.com/embed/41-4ZOMK6oE?si=kcXFc-uasZK8Hfbq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<div class="slide">
    <iframe height='300' src="https://www.youtube.com/embed/F4bZd_2jIVs?si=MHRwEISqm4zY9vTj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		
	</div>
</div>  */}

        {/* <div className="slider" id='vedios'>
      <div className="testimonial-headers"><h1>Testimonials</h1></div>
      <div className="slide-track">
        {videos.map((videoUrl, index) => (
          <div className="slide" key={index}>
            <iframe
              id={`video${index + 1}`}
              height="300"
              width="560"
              src={videoUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              onClick={() => handleVideoClick(index)}
            ></iframe>
          </div>
        ))}
      </div>
    </div> */}
        {/* <div>
      <div className="slider" id='vedios'>
        <div className="testimonial-headers"><h1>Testimonials</h1></div>
        <div className="slide-track">
          {videos.map((videoUrl, index) => (
            <div className="slide" key={index}>
              <iframe
                id={`video${index + 1}`}
                height="300"
                width="560"
                src={videoUrl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                onClick={() => handleVideoClick(videoUrl)}
              ></iframe>
            </div>
          ))}
        </div>
      </div>

      {selectedVideo && (
        <div className="large-video-container">
          <iframe
            id="largeVideo"
            width="800"
            height="450"
            src={selectedVideo}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div> */}


        {/* ...............................movivg........................................ */}

      </div>





      {/* <GoogleRecaptcha /> */}
      <GoogleRecaptcha />

      <Footer />
    </div>
  )
}

export default HomePage
