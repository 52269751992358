import React from 'react'
import '../styles/AedPay.css'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'
import Footer from './Footer'

const AedPay = () => {
    return (
        <div className='pay-opt'>
            <Navbar/>
            <div className="container">
                <div className="pay_sec">
                    <div className="pay_title">
                        <h1>CHOOSE FROM THE FOLLOWING PAYMENT OPTIONS</h1>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">

                            <div className="transfer-card">
                                <button>1</button>
                                <h3>Credit/Debit Cards</h3>
                                <Link to='https://rzp.io/l/2Fl6WbR3J'><p>CLICK HERE</p></Link>
                            </div>

                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">

                            <div className="transfer-bank">
                                <button>2</button>
                                <h3>Cash Deposit or Money Transfer</h3>
                                <p>Account Name: ASCEDU SMARTCLASSON LLP</p>
                                <p>Account Number: 923020014345748</p>
                                <p>IFSC Code: UTIB0003739</p>
                                <p>Bank Name: Axis Bank Limited</p>
                                <p>MICR- 673211009</p>
                                <p>SWIT CODE- AXISINBB136</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default AedPay
