import React from 'react'
import '../styles/Footer.css'

import { FaMapMarkerAlt, FaPhone } from "react-icons/fa";
import { PiPaperPlaneTiltFill } from "react-icons/pi";
import { IoMdMail } from "react-icons/io";
import { Link } from 'react-router-dom';
import BackToTop from './BackToTop';
import logos from '../assets/Images/PTESKILLS.png'

const Footer = () => {
    return (
        <div>
            <footer id="site-footer">

                <section className="horizontal-footer-section" id="footer-middle-section">


                    <div id="footer-about" className="footer-columns footer-columns-large links_set" >
                        <h1>QUICK LINKS</h1>
                        <address>
                            {/* <Link  target="_blank" rel="noreferrer" to=''><p className='address-details'><span><FaMapMarkerAlt /></span> <div>Asian Study Centre, Subang Jaya, Malaysia 47500</div></p></Link> */}
                            <Link to='https://courses.ieltsmaster.com/PTESKILLS'><p className='address-details'><div>COURSES</div></p></Link>
                            <p onClick={() => {
                                window.location.href = '/trial-class/';
                            }} className='address-details'><div>TRIAL CLASSES</div></p>
                            {/* <Link to='https://reports2.ieltsmaster.com/index.php/Student'><p className='address-details'><div>LOGIN</div></p></Link> */}
                        </address>
                    </div>
                    <div id="footer-about" className="footer-columns footer-columns-large">

                        <address>
                            {/* <p className='address-details'><span><FaMapMarkerAlt /></span> <div>Asian Study Centre Educational Services Pvt. Ltd.</div></p> */}
                            {/* <p className='address-details'><span><PiPaperPlaneTiltFill /></span> <div>Mumbai | Bengaluru | Kerala</div></p> */}
                            <Link to=''><p className='address-details'><div><img id='img-logo' src={''} alt="" /></div></p></Link>
                            {/* <Link to='mailto:info@ieltsmaster.com'><p className='address-details'><span><IoMdMail /></span> <div>info@ieltsmaster.com</div></p></Link> */}
                        </address>
                    </div>



                    <div id="footer-about" className="footer-columns footer-columns-large">
                        <h1>ASCEDU SMARTCLASSON LLP</h1>
                        <address>
                            <Link target="_blank" rel="noreferrer" to='https://www.google.com/maps/place/Logic+School+of+Management+Calicut/@11.2428667,75.785169,17z/data=!3m1!4b1!4m6!3m5!1s0x3ba6594056f3e251:0x84cce62cf91f1d60!8m2!3d11.2428667!4d75.7877439!16s%2Fg%2F11c44d62hn?entry=ttu'><p className='address-details'><span><FaMapMarkerAlt /></span> <div id='location'>CALICUT | BANGLORE| MUMBAI</div></p></Link>
                            <Link to='tel:+919496582854'><p className='address-details'><span><FaPhone /></span> <div>+919496582854</div></p></Link>
                            <Link to='mailto:info@pteskills.com'><p className='address-details'><span><IoMdMail /></span> <div>info@pteskills.com</div></p></Link>
                        </address>
                    </div>

                </section>

                <section className="horizontal-footer-section" id="footer-bottom-section">
                    <div id="footer-copyright-info">
                        COPYRIGHT &copy; 2023 - <Link>SmartClassON</Link> | ALL RIGHTS RESERVED
                    </div>
                    <div id="footer-social-buttons">
                        <BackToTop />
                    </div>
                </section>

            </footer>
        </div>
    )
}

export default Footer
