import React, { useState } from 'react';
import countryData from 'country-data';
import '../styles/InputContent.css'

//installed - npm install country-data --save  and  npm install --save font-awesome



import 'font-awesome/css/font-awesome.min.css'; // Import the Font Awesome CSS

function InputContent() {
  const [selectedCountry, setSelectedCountry] = useState('India (+91)');
  const [showDropdown, setShowDropdown] = useState(false);

  const countryCodesArray = Object.values(countryData.countries.all).map((country) => ({
    name: country.name,
    dial_code: country.countryCallingCodes[0],
  }));

  const handleCountryClick = (country) => {
    setSelectedCountry(`${country.name} (${country.dial_code})`);
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div>
      <div className="custom-dropdown">
        <input
          name='country'
          type="text"
          value={selectedCountry}
          onClick={toggleDropdown}
          readOnly
        />
        <i className={`fa fa-caret-down ${showDropdown ? 'up' : 'down'}`} onClick={toggleDropdown}></i>
        {showDropdown ? (
          <div className="country-dropdown">
            {countryCodesArray.map((country, index) => (
              <div
                key={index}
                className="country-option"
                onClick={() => handleCountryClick(country)}
              >
                {country.name} ({country.dial_code})
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default InputContent;
