
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";


import HomePage from './component/HomePage';

import { Helmet } from 'react-helmet';
import TrialClass from './component/TrialClass';
import PayNow from './component/PayNow';
import AedPay from './component/AedPay';
import UsdPay from './component/UsdPay';
import MailSuccess from './component/MailSuccess';
import MailFailure from './component/MailFailure';





function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Helmet>
          <title>PTE Skills</title>
          <meta
            name='description'
            content='PTE Skills stands out as an eminent PTE coaching platform on a global scale'
          />
          <meta name='keyword' content='PTE coaching platform'/>
          <link rel="canonical" href="https://www.PTE skills.com/"></link>
        </Helmet>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/trial-class/' element={<TrialClass/>}/>
          <Route path='/pay-now' element={<PayNow/>}/>
          <Route path='/aed-pay' element={<AedPay/>}/>
          <Route path='/usd-pay' element={<UsdPay/>}/>
          <Route path='/mail-success' element={<MailSuccess/>}/>
          <Route path='/mail-failure' element={<MailFailure/>}/>
        </Routes>

      </BrowserRouter>
    </div>
  );
}

export default App;
