import React, { useEffect } from 'react';
import '../styles/MailSuccess.css';
import { MdVerified } from 'react-icons/md';
import { Link } from 'react-router-dom';

const MailSuccess = () => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Redirect to the desired URL after 15 seconds
      window.location.replace('/');
    }, 10000);

    // Clear the timeout if the component is unmounted
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className='success-email'>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12">
            <div className="success-page text-center">
              <div className="verify">
                <div className="verify-icon"><MdVerified /></div>
                <h1>Thank you!</h1>
                <p>Your submission has been sent.</p>
                <Link to='/'><button>Back to home</button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MailSuccess;
